import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../services/nav.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr'
    }
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  };

  constructor(public navServices: NavService, private translate: TranslateService) { }

  //
  ngOnInit() {
    this.translate.use('en');
  }

  changeLanguage(lang): void {
    console.log(lang);
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

}
