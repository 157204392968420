<div class="page-wrapper">
    <div class="container-fluid p-0">
        <!-- Unlock page start-->
        <div class="authentication-main mt-0">
            <div class="row">
                <div class="col-12">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo"  routerLink='/'>
                                    <!--<img class="img-fluid for-light" src="assets/images/logo/ss_main_logo.png" height="100" width="400" alt="Smart School">
                                    <img class="img-fluid for-dark" src="assets/images/logo/ss_main_logo_white.png" height="100" width="400" alt="Smart School">-->

                                    <img class="img-fluid for-light" src="assets/images/logo/quidak.png" alt="Quidak">
                                    <img class="img-fluid for-dark" src="assets/images/logo/quidak-white.png" alt="Quidak">
                                </a>
                            </div>
                            <div class="login-main">
                                <form class="theme-form" [formGroup]="phoneVerificationForm" (ngSubmit)="userPhoneVerification()">
                                    <h4>{{ 'Reset Your Password'|translate }}</h4>
                                    <div class="form-group">
                                        <label class="col-form-label">{{ 'enter your mobile number'|translate }}</label>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <input class="form-control mb-1" type="text" value="+ 237">
                                            </div>
                                            <div class="col-md-9">
                                                <input class="form-control mb-1"
                                                       formControlName="phone"
                                                       type="number"
                                                       placeholder="677777777"
                                                >
                                            </div>
                                            <div class="col-12">
                                                <!--                                                <button class="btn btn-primary d-block w-100 m-t-10" type="submit">Send</button>-->
                                                <button class="btn btn-primary d-block w-100 m-t-10" type="submit" [disabled]=login>
                                                    <ng-container *ngIf="!login; else isLogin">{{ 'send'|translate }}</ng-container>
                                                    <ng-template #isLogin>
                                                        <button class="btn btn-primary"><i class="fa fa-spin fa-spinner me-2"></i>{{ 'loading...'|translate }}</button>
                                                    </ng-template>
                                                </button>
                                            </div>
                                        </div>
                                        <!--<p class="mt-4 mb-0">{{ 'already Have an account?'|translate }}<a class="ms-2" [routerLink]="'/auth/login'">{{ 'sign in'|translate }}</a></p>-->
                                        <p class="mt-4 mb-0" style="cursor: pointer">{{ 'already Have an account?'|translate }}<a class="ms-2" (click)="goBack()">{{ 'sign in'|translate }}</a></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Unlock page end-->
    </div>
</div>
