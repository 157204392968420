import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../services/auth.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-teacher-login',
  templateUrl: './teacher-login.component.html',
  styleUrls: ['./teacher-login.component.scss']
})
export class TeacherLoginComponent implements OnInit {

  public show: boolean = false;

  public signinForm: FormGroup;
  public errorMessage: any;
  public alertMessage = '';
  public login = false;
  public isSubmitted = false;

  constructor(public fb: FormBuilder,
              public authService: AuthService,
              public translate: TranslateService,
              public router: Router,
              public toast: ToastrService) {
    this.signinForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit() {
  }

  loginUser() {

    this.isSubmitted = true;
    if (this.signinForm.valid){
      this.login = true;
      this.authService.coreSignIn(this.signinForm.value, 'teacher').subscribe({
        next: (res: any) =>
        {
          console.log(res.user);

          localStorage.setItem('user_token', res.token);

          const currenUserFirstname = res.user.firstname;
          const currentUserLastname = res.user.lastname;

          this.login = false;

          if (res.user.loginCount == 1){
            console.log(res.user.loginCount);
            localStorage.setItem('id', res.user.id);
            this.router.navigate(['/auth/forgotten/password/reset']);
          }else {
            if (res.user.maintenance === true){
              localStorage.setItem('user', JSON.stringify(res.user));
              console.log(res.user.maintenance)
              console.log('hello...')
              this.router.navigate(['/maintenance']);
            }
            else {
              if (res.user.isIsStudentSystem || res.user.isIsTeacherSystem){

                if (res.user.twoAuthMode === true){
                  this.router.navigate(['auth/login/opt']);
                  localStorage.setItem('id', res.user.id);
                  localStorage.setItem('mode', res.user.twoAuthMode);
                  localStorage.setItem('transport', res.user.twoAuthTransport);
                }
                else {

                  console.log(res.user.loginCount);

                  localStorage.setItem('user', JSON.stringify(res.user));

                  localStorage.setItem('moduleId', res.user.moduleId);

                  if (res.user.moduleId && res.user.modulePath){
                    const queryParams = { layout: res.user.moduleLayout };
                    this.router.navigate([res.user.modulePath], { queryParams });
                  }
                  else {
                    this.router.navigate(['/dashboard/default']);
                  }

                  this.alertMessage =
                      'Succesful auhentification, welcome ' + currenUserFirstname + ' ' + currentUserLastname;

                  this.toast.info(this.alertMessage, 'Info');

                }

              }
              else {

                if (res.user.twoAuthMode === true){
                  this.router.navigate(['auth/login/opt']);
                  localStorage.setItem('id', res.user.id);
                  localStorage.setItem('mode', res.user.twoAuthMode);
                  localStorage.setItem('transport', res.user.twoAuthTransport);

                }else {

                  localStorage.setItem('user', JSON.stringify(res.user));

                  localStorage.setItem('moduleId', res.user.moduleId);

                  const queryParams = { layout: 'Singapore' };
                  this.router.navigate(['/dashboard/loading-module'], { queryParams });

                  this.alertMessage =
                      'Succesful auhentification, welcome ' + currenUserFirstname + ' ' + currentUserLastname;

                  this.toast.info(this.alertMessage, 'Info');

                }

              }
            }
          }

        },
        error: (err: any) =>
        {
          this.login = false;
          console.log(err);

          this.alertMessage = err.message;

          this.toast.warning(this.alertMessage, 'Warning');

          // console.log(err['error']['message']);
        }

      });
    }

  }

  showPassword() {
    this.show = !this.show;
  }

}
