<div class="modal-header">
  <h4 class="modal-title">{{ 'password modification'|translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="userForm" (ngSubmit)="onFormSubmit()" id="ngFormChangePassword">

    <div class="row">
      <div class="col-md-12 mb-3">
        <label class="form-label">{{ 'old password'|translate }} <span class="text-danger">*</span></label>
        <input class="form-control form-control-sm bg-gradient" type="password" placeholder="********" formControlName="oldPassword" [ngClass]="{'is-invalid': fc['oldPassword'].invalid && isSubmitted}">
        <small class="invalid-feedback fst-italic" *ngIf="fc['oldPassword'].errors?.['required']">{{ 'this field is required'|translate }}</small>
        <div class="invalid-feedback" *ngIf="fc['oldPassword'].errors?.['serverError']">
          {{ fc['oldPassword'].errors?.['serverError'] }}
        </div>
      </div>
      <div class="col-md-12 mb-3">
        <label class="form-label">{{ 'new password'|translate }} <span class="text-danger">*</span></label>
        <input class="form-control form-control-sm bg-gradient" type="password" placeholder="********" formControlName="newPassword" [ngClass]="{'is-invalid': fc['newPassword'].invalid && isSubmitted}">
        <small class="invalid-feedback fst-italic" *ngIf="fc['newPassword'].errors?.['required']">{{ 'this field is required'|translate }}</small>
        <div class="invalid-feedback" *ngIf="fc['newPassword'].errors?.['minlength']">{{ 'password must be at least 3 characters long'|translate }}</div>
        <div class="invalid-feedback" *ngIf="fc['newPassword'].errors?.['serverError']">
          {{ fc['newPassword'].errors?.['serverError'] }}
        </div>
      </div>
      <div class="col-md-12">
        <label class="form-label">{{ 'confirm password'|translate }} <span class="text-danger">*</span></label>
        <input class="form-control form-control-sm bg-gradient" type="password" placeholder="********" formControlName="confirmNewPassword" [ngClass]="{'is-invalid': fc['confirmNewPassword'].invalid && isSubmitted}">
        <small class="invalid-feedback fst-italic" *ngIf="fc['confirmNewPassword'].errors?.['required']">{{ 'this field is required'|translate }}</small>
        <div class="invalid-feedback" *ngIf="fc['confirmNewPassword'].errors?.['minlength']">{{ 'password must be at least 3 characters long'|translate }}</div>
        <div class="invalid-feedback" *ngIf="fc['confirmNewPassword'].errors?.['serverError']">
          {{ fc['confirmNewPassword'].errors?.['serverError'] }}
        </div>
      </div>
    </div>

  </form>
</div>
<div class="modal-footer">

    <button form="ngFormChangePassword" style="margin-right: 10px;" (submit)="onFormSubmit()" type="submit" class="btn btn-sm btn-primary m-r-10">
        <i class="fa fa-save" *ngIf="!saving; else isSaving" style="margin-right: 5px;"></i>
        <ng-template #isSaving>
            <i class="fa fa-spin fa-spinner me-2"></i>
        </ng-template>
        {{ 'save'|translate }}
    </button>

    <button  type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">
        <i class="icofont icofont-ui-close" style="margin-right: 2px;"></i>
        {{ 'close'|translate }}
    </button>

</div>