<div class="container-fluid">
  <div class="row">
    <div class="col-xl-5" [ngStyle]="{'background-image': 'url(assets/images/login/student.jpg)',
                                      'background-size': 'cover',
                                      'background-position': 'center center',
                                      'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/student.jpg" alt="looginpage">
    </div>
    <div class="col-xl-7 p-0">    
      <div class="login-card">
        <div>
          <div>
            <a class="logo" href="#">
              <img class="img-fluid for-light" src="assets/images/logo/sss-logo.png" alt="Smart School">
              <img class="img-fluid for-dark" src="assets/images/logo/sss-logo-white.png" alt="Smart School">
            </a>
          </div>
          <div class="login-main"> 
            <form class="theme-form" [formGroup]="signinForm" (ngSubmit)="loginUser()">
              <h4>{{ 'sign in to account'|translate }}</h4>
              <p>{{ 'enter your email & password to login'|translate }}</p>
              <div class="form-group">
                <label class="col-form-label">{{ 'username or email address'|translate }}</label>
                <input
                        formControlName="username"
                        class="form-control"
                        type="email"
                        required=""
                        placeholder="app@yourmail.com">
              </div>
              <div class="form-group">
                <label class="col-form-label">{{ 'password'|translate }}</label>
                <input
                        formControlName="password"
                        class="form-control"
                        [type]="show ? 'text' : 'password'"
                        name="login[password]"
                        required=""
                        placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-muted" style="color: black !important;" for="checkbox1">{{ 'remember password'|translate }}</label>
                </div>
                <a [routerLink]="'/auth/forgotten/password'" class="link">{{ 'forgot password'|translate }}?</a>
                <button type="submit" class="btn btn-primary w-100" tabindex="4" [disabled]=login>
                  <ng-container *ngIf="!login; else isLogin">{{ 'sign in'|translate }}</ng-container>
                  <ng-template #isLogin>
                    <button class="btn btn-primary"><i class="fa fa-spin fa-spinner me-2"></i>{{ 'loading...'|translate }}</button>
                  </ng-template>
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
