<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type">
  <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">

      <div class="sidebar-wrapper" *ngIf="layout.config.settings.layout !== 'Singapore'" [attr.icon]="layout.config.settings.icon" [class.close_icon]="navServices.collapseSidebar">
        <app-sidebar></app-sidebar>
      </div>

<!--      <div class="sidebar-wrapper" *ngIf="layout.config.settings.layout == 'Singapore'">-->

<!--        <div class="container-fluid">-->
<!--        <div class="page-title" style="background: #C3C4D9">-->
<!--          <div class="row">-->
<!--            <div class="col-6">-->
<!--              <h5>MODULE LIST</h5>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        </div>-->

<!--      </div>-->

      <div class="page-body">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>

    <!-- Page Body End-->
  </div>


  </div>

<div class="bg-overlay1"></div>
<!--<app-customizer></app-customizer>-->
<!-- page-wrapper End-->
