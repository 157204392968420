import {Component, ViewEncapsulation, HostListener, OnInit, AfterViewInit, OnChanges, SimpleChanges, Input} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {SharedDataService} from "../../services/shared-data.service";
import {MenuService} from "../../../components/security/services/menu.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, OnChanges  {
  public userMenus: any[];

  public iconSidebar;

  public userLayout = localStorage.getItem('layout');
  public checkProfile: string;

  @Input()
  public menuItems: Menu[];
    public userPermissions = [];



      public menus = [

          // Security module
          this.translate.instant('profile'),
          this.translate.instant('user'),
          this.translate.instant('institution'),
          this.translate.instant('branch'),
          this.translate.instant('session'),
          this.translate.instant('year'),
          this.translate.instant('current year'),
          this.translate.instant('system settings'),
          // Security module end

          // Product module
          this.translate.instant('configuration'),
          this.translate.instant('fee payment scheme'),
          this.translate.instant('fee type'),
          this.translate.instant('unit category'),
          this.translate.instant('unit'),
          this.translate.instant('attribute'),
          this.translate.instant('attribute value'),
          this.translate.instant('billing policy'),
          this.translate.instant('cost strategy'),
          this.translate.instant('subtract strategy'),
          this.translate.instant('item type'),
          this.translate.instant('item category'),

          this.translate.instant('fee'),
          this.translate.instant('fee installment'),

          this.translate.instant('item'),
          this.translate.instant('packaging'),
          // Product module End

          // Treasury module
          this.translate.instant('cash desk'),
          this.translate.instant('cash desk operation'),
          this.translate.instant('bank'),
          this.translate.instant('bank account'),
          this.translate.instant('bank account operation'),
          // Treasury module end

          // Partner module
          this.translate.instant('partner category'),
          this.translate.instant('supplier'),
          this.translate.instant('customer'),
          this.translate.instant('contact'),
          // Treasury module end

          // Inventory module
          this.translate.instant('warehouse'),
          this.translate.instant('location'),
          this.translate.instant('stock'),
          this.translate.instant('stock movement'),
          this.translate.instant('reception'),
          this.translate.instant('delivery'),
          // Inventory module End

          // Sale module
          this.translate.instant('invoice'),
          this.translate.instant('return invoice'),
          this.translate.instant('settlement'),
          // Sale module End

          // Report
          this.translate.instant('stock movement history'),
          this.translate.instant('stock list'),
          this.translate.instant('stock valuation'),

          this.translate.instant('cash desk history'),
          this.translate.instant('bank account history'),

          this.translate.instant('sale payment history'),
          this.translate.instant('sale refund history'),

          this.translate.instant('purchase payment history'),
          this.translate.instant('purchase refund history'),
          // Report end

          // Setting
          this.translate.instant('human resource'),
          this.translate.instant('employment status'),
          this.translate.instant('leave character'),
          this.translate.instant('leave type'),

          this.translate.instant('institution'),
          this.translate.instant('ministry'),
          this.translate.instant('manager type'),

          this.translate.instant('location'),
          this.translate.instant('country'),
          this.translate.instant('region'),

          this.translate.instant('person'),
          this.translate.instant('blood group'),
          this.translate.instant('civility'),
          this.translate.instant('rhesus'),
          this.translate.instant('sex'),
          this.translate.instant('religion'),
          this.translate.instant('identity type'),
          this.translate.instant('marital status'),

          this.translate.instant('diploma type'),
          this.translate.instant('repeating'),
          this.translate.instant('subject nature'),
          this.translate.instant('period type'),

      ];

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  constructor(private router: Router, public navServices: NavService, private sharedDataService: SharedDataService,
              private menuService: MenuService,
              public layout: LayoutService, public translate: TranslateService) {

    // this.navServices.items.subscribe(menuItems => {
    //   this.menuItems = menuItems;
    //   console.log(menuItems);
    //   this.router.events.subscribe((event) => {
    //     // console.log(event);
    //     if (event instanceof NavigationEnd) {
    //       menuItems.filter(items => {
    //         console.log(items);
    //         if (items.path === event.url) {
    //           this.setNavActive(items);
    //         }
    //         if (!items.children) { return false; }
    //         items.children.filter(subItems => {
    //           if (subItems.path === event.url) {
    //             this.setNavActive(subItems);
    //           }
    //           if (!subItems.children) { return false; }
    //           subItems.children.filter(subSubItems => {
    //             if (subSubItems.path === event.url) {
    //               this.setNavActive(subSubItems);
    //             }
    //           });
    //         });
    //       });
    //     }
    //   });
    // });



  }

    ngOnInit(): void {

        const moduleId = localStorage.getItem('moduleId');
        this.checkProfile = JSON.parse(localStorage.getItem('user'))['profile'];
        this.navServices.loadMenu(moduleId).subscribe(menuItems  => {
                console.log(menuItems.modules);
                const datas = menuItems.modules;
                // const type = datas.type;
                // const objt: any = {};

                this.menuItems = datas.map((m) => {
                    // if (type === 'link'){

                    if (window.location.pathname == m.path){
                        this.menuService.getPermissionsByMenuId(m.id).subscribe({
                            next: (data: any) => {
                                console.log(data['hydra:member'])
                                this.userPermissions = data['hydra:member'];

                                this.sharedDataService.setPermissions(data['hydra:member']);
                            },
                            error:(err: HttpErrorResponse) =>{
                                console.log(err);
                            }
                        });
                    }

                    return {
                        id: m.id,
                        icon : m.icon,
                        path : m.path,
                        title : m.name,
                        type : m.type,
                        // bookmark: v.bookmark

                        children: m.children?.map((v: any) => {

                            if (window.location.pathname == v.path){
                                this.menuService.getPermissionsByMenuId(v.id).subscribe({
                                    next: (data: any) => {
                                        console.log(data['hydra:member'])
                                        this.userPermissions = data['hydra:member'];

                                        this.sharedDataService.setPermissions(data['hydra:member']);
                                    },
                                    error:(err: HttpErrorResponse) =>{
                                        console.log(err);
                                    }
                                });
                            }

                            return {
                                id: v.id,
                                path : v.path,
                                title : v.name,
                                type : v.type
                            };


                        })

                    };

                });
            },
        );

  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges', changes);
    this.menuItems = changes.menuItems.currentValue;
    this.router.events.subscribe((event) => {
      // console.log(event);
      if (event instanceof NavigationEnd) {
        this.menuItems.filter(items => {
          console.log(items);
          if (items.path === event.url) {
            this.setNavActive(items);
          }
          if (!items.children) { return false; }
          items.children.filter(subItems => {
            if (subItems.path === event.url) {
              this.setNavActive(subItems);
            }
            if (!subItems.children) { return false; }
            subItems.children.filter(subSubItems => {
              if (subSubItems.path === event.url) {
                this.setNavActive(subSubItems);
              }
            });
          });
        });
      }
    });
  }

    menuClick(menuId: any): void {

        this.menuService.getPermissionsByMenuId(menuId).subscribe({
            next: (data: any) => {
                console.log(data['hydra:member'])
                this.userPermissions = data['hydra:member'];

                this.sharedDataService.setPermissions(data['hydra:member']);
            },
            error:(err: HttpErrorResponse) =>{
                console.log(err);
            }
        });
    }

}
